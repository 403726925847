import { NavLink, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";

import SailIcon from '../../assets/img/icons/sail.svg'
import CheckGreenIcon from '../../assets/img/icons/check-green.svg'
import SortIcon from '../../assets/img/icons/sort.svg';
import SortAsc from '../../assets/img/icons/sort-asc.svg';
import SortDesc from '../../assets/img/icons/sort-desc.svg';

import ascIcon from '../../assets/img/icons/asc.svg';
import ascAscIcon from '../../assets/img/icons/asc-asc.svg';
import ascDescIcon from '../../assets/img/icons/asc-desc.svg';

import ArrowLeft from '../../assets/img/icons/arrow-left.svg';
import ArrowRight from '../../assets/img/icons/arrow-right.svg';

import { statusButtons } from '../../components/parts/StatusButtons';
import { useDispatch, useSelector } from "react-redux";
import { getAllMissions } from "../../store/slices/missionSlice";
import { fetchAllMission } from "../../store/thunks/fleetThunks";
import NamedFilter from '../../components/NamedFilter';
import api from '../../api';
import { format } from 'date-fns';
import { useClickOutside } from '../../helpers/useClickOutside';
import { getFilters } from '../../store/slices/filtersSlice';
import { fetchDeleteFilter, fetchFilters, fetchUpdateFilter } from '../../store/thunks/filtersThunks';

const ConfigHeader = ({ onClose }) => (
    <div className="flex gap-5 justify-between px-2 py-1 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:text-white dark:bg-[#151515]">
        <div>Edit filter</div>
        <button onClick={onClose}>
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddcb33ebb0cb0d777eaebba2ca6701637f967354c4f2b41114a225bd26c5a850?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
                className="shrink-0 my-auto w-3 aspect-square close-icon"
            />
        </button>
    </div>
);

export const EditFilterModal = ({ filter, savedFilters, onClose }) => {
    const dispatch = useDispatch()
    const [name, setName] = useState(filter.name);
    const [vessel, setVessel] = useState(filter.vesselId);
    const [startDate, setStartDate] = useState(filter.startDate);
    const [endDate, setEndDate] = useState(filter.endDate);
    const [allVessels, setAllVessel] = useState([]);

    const [vesselsDropdownOpen, setVesselsDropdownOpen] = useState(false);

    const dropdownRef = useRef();
    useClickOutside(dropdownRef, () => setVesselsDropdownOpen(false));

    const handleVesselSelect = (vessel) => {
        setVessel(vessel);
        setVesselsDropdownOpen(false);
    }


    const getAllFleets = async () => {
        let res = await api.fleet.getAllVessel();
        setAllVessel(res.data);
    }

    useEffect(() => {
        getAllFleets();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(fetchUpdateFilter({
            payload: {
                name,
                startDate,
                endDate,
                vesselId: vessel
            },
            filterId: filter.id
        }))
        onClose && onClose();
    };

    return (
        <div style={{ position: 'fixed', width: 362, top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}
            className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 max-w-[336px] border border-solid border-neutral-950 border-opacity-30 dark:border-[#ffffff1a] dark:bg-[#1b1b1b]">
            <ConfigHeader onClose={onClose} />
            <div className="flex flex-col justify-end px-3 pb-3 mt-4 w-full font-semibold text-neutral-950">
                <form onSubmit={handleSubmit} className="grid items-center grid-cols-1 gap-2">
                    <div className='flex flex-col items-center'>
                        <label className='block mb-2'>Filter name</label>
                        <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            required
                            placeholder='Filter name'
                            className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
                        />
                    </div>
                    <div className='flex flex-col items-center'>
                        <label className='block mb-2'>Vessel name</label>
                        <div className="relative" ref={dropdownRef}>
                            <div
                                onClick={() => setVesselsDropdownOpen(!vesselsDropdownOpen)}
                            >
                                <input
                                    value={allVessels.find(item => item.id === vessel)?.name || ''}
                                    onChange={(e) => setVessel(e.target.value)}
                                    type="text"
                                    required
                                    placeholder='Vessel name'
                                    className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
                                />
                            </div>
                            {vesselsDropdownOpen && (
                                <ul className="absolute flex flex-col right-[-1px] top-[calc(100%+2px)] w-full rounded-b-[2px] rounded-t-0 border-t-0 border border-[#0B0B0B1A] bg-white min-w-[calc(100%+2px)] z-[1000] max-h-[180px] overflow-y-auto">
                                    {allVessels.length ? allVessels.map((vessel, index) => (
                                        <li key={index} className="flex items-center gap-3 hover:bg-slate-50 p-2">
                                            <div onClick={() => handleVesselSelect(vessel.id)} className="flex-auto truncate cursor-pointer">
                                                {vessel.name}
                                            </div>
                                        </li>
                                    )) : (
                                        <li className='flex items-center gap-3 text-center p-2'>
                                            There is no saved filters yet
                                        </li>
                                    )}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-col items-center'>
                        <label className='block mb-2'>Start Date</label>
                        <DatePicker
                            wrapperClassName='!w-auto'
                            className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
                            // className="px-2 min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
                            toggleCalendarOnIconClick
                            showTimeSelect
                            selected={startDate ? new Date(startDate) : null}
                            placeholderText="Start Date"
                            onChange={(date) => setStartDate(date.toISOString())}
                            required
                            dateFormat='dd/MM/yy, HH:mm'
                        />
                    </div>
                    <div className='flex flex-col items-center'>
                        <label className='block mb-2'>End Date</label>
                        <DatePicker
                            wrapperClassName='!w-auto'
                            className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
                            // className="px-2 min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
                            toggleCalendarOnIconClick
                            showTimeSelect
                            selected={endDate ? new Date(endDate) : null}
                            placeholderText="End Date"
                            onChange={(date) => setEndDate(date.toISOString())}
                            required
                            dateFormat='dd/MM/yy, HH:mm'
                        />
                    </div>
                    <div className='flex justify-between gap-4'>
                        <button
                            onClick={onClose}
                            className='group flex items-center justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]'
                            type="button"
                        >
                            Cancel
                        </button>
                        <button
                            className='group flex items-center justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]'
                            type="submit"
                        >
                            Save Filter
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}



const tableFilters = [
    { title: 'Id', id: 'id', filterable: true, sortable: true, sortValue: 0, filterValue: '' },
    { title: 'Vessel Name', id: 'vesselId', filterable: true, sortable: true, sortValue: 0, filterValue: '' },
    // { title: 'Mission Description', id: 'description', filterable: true, sortable: true, sortValue: 0, filterValue: '' },
    { title: 'Filter Name', id: 'description', filterable: true, sortable: true, sortValue: 0, filterValue: '' },

    { title: 'Filter Start', id: 'start', filterable: true, sortable: true, sortValue: 0, filterValue: '', asc: true, ascValue: 0, fType: 'date' }, //ascValue: 0 || 1 || -1
    { title: 'Filter End', id: 'end', filterable: true, sortable: true, sortValue: 0, filterValue: '', asc: true, ascValue: 0, fType: 'date' },
    // { title: 'Status', id: 'status', filterable: false, sortable: true, sortValue: 0, filterValue: '', },
]

const formatSortValueToIcon = (ascValue) => {
    if (ascValue > 0) return SortAsc;
    if (ascValue < 0) return SortDesc;
    return SortIcon;
}

const formatAscValueToIcon = (ascValue) => {
    if (ascValue > 0) return ascAscIcon;
    if (ascValue < 0) return ascDescIcon;
    return ascIcon;
}
export const DataExplorer = () => {
    const dispatch = useDispatch()

    const [filters, setFilters] = useState(tableFilters);

    const [filterToEdit, setFilterToEdit] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const allMissions = useSelector(getAllMissions)


    const savedFilters = useSelector(getFilters)
    console.log(savedFilters);
    

    const [allVessels, setAllVessel] = useState([]);
    const getAllFleets = async () => {
        let res = await api.fleet.getAllVessel();
        setAllVessel(res.data);
    }
    

    useEffect(() => {
        getAllFleets()
        dispatch(fetchFilters())
        const req = async () => {
            setIsLoading(true)
            const res = await dispatch(fetchAllMission())
            setIsLoading(false)
        }

        if (allMissions === null) {
            req()
        }
        

    }, []);



    // const handleascChange = (index) => {
    //     setFilters((oldFilters) => {
    //         const newFilters = [...oldFilters];
    //         const target = newFilters[index].ascValue;
    //         if (target === 0) newFilters[index].ascValue = 1;
    //         else if (target === 1) newFilters[index].ascValue = -1;
    //         else if (target === -1) newFilters[index].ascValue = 0;
    //         return newFilters
    //     });
    // }

    // const handleSortChange = (index) => {
    //     const newFilters = [...filters];
    //     const target = newFilters[index].sortValue;
    //     if (target === 0) newFilters[index].sortValue = 1;
    //     else if (target === 1) newFilters[index].sortValue = -1;
    //     else if (target === -1) newFilters[index].sortValue = 0;
    //     setFilters(newFilters);
    // }

    // const handleFilterInput = (e, index) => {
    //     const { name, value } = e.target;
    //     setFilters((oldFilters) => {
    //         const newFilters = [...oldFilters];
    //         newFilters[index].filterValue = value;
    //         return newFilters;
    //     });

    // }



    // useEffect(() => {
    //     // TODO: Implement sorting and filtering
    //     if (!allMissions) return
    //     const newFilteredData = allMissions.filter((row) => {
    //         return filters.every((filter, index) => {
    //             if (filter.filterValue === '') return true;
    //             if (filter.id === "start") {
    //                 return +(new Date(row[filter.id])) >= +(new Date(filter.filterValue))
    //             }
    //             if (filter.id === "end") {
    //                 return +(new Date(row[filter.id])) <= +(new Date(filter.filterValue))
    //             }
    //             return String(row[filter.id]).toLowerCase().includes(String(filter.filterValue).toLowerCase());
    //         });
    //     });

    //     newFilteredData.sort((a, b) => {
    //         return filters.reduce((acc, filter, index) => {
    //             if (filter.sortValue === 0) return acc;
    //             const valueA = a[filter.id];
    //             const valueB = b[filter.id];
    //             if (valueA < valueB) return acc + filter.sortValue;
    //             if (valueA > valueB) return acc - filter.sortValue;
    //             return acc;
    //         }, 0);
    //     });

    //     newFilteredData.sort((a, b) => {
    //         return filters.reduce((acc, filter, index) => {
    //             if (!filter.asc) return acc;
    //             if (filter.ascValue === 0) return acc;
    //             const valueA = a[filter.id];
    //             const valueB = b[filter.id];
    //             if (valueA < valueB) return acc + filter.ascValue;
    //             if (valueA > valueB) return acc - filter.ascValue;
    //             return acc;
    //         }, 0);
    //     });

    //     setFilteredData(newFilteredData);
    // }, [filters]);

    const navigate = useNavigate();
    const handleFilterChange = (filter) => {
        if (!filter) return
        navigate(`/data-explorer/result?vesselId=${filter.vessel}&startDate=${(new Date(filter.startDate).toISOString())}&endDate=${(new Date(filter.endDate).toISOString())}`)
        // setFilters((oldFilters) => {
        //     const newFilters = [...oldFilters];
        //     newFilters[1].filterValue = filter?.vessel ?? "";
        //     newFilters[3].filterValue = filter?.startDate ?? "";
        //     // newFilters[4].filterValue = filter?.endDate ?? "";
        //     return newFilters;
        // });
    }

    const handleEditFilter = (filter) => {
        setFilterToEdit(filter)
    }
    const handleDeleteFilter = (id) => {
        dispatch(fetchDeleteFilter(id))
    }

    return (
        <>
            <header className="flex gap-5 justify-between pb-2 w-full border-b border-solid border-b-[color:var(--black-25,rgba(11,11,11,0.25))] max-md:flex-wrap max-md:max-w-full">
                <div className="flex gap-2 justify-between max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col flex-1 justify-center text-neutral-950 pr-2">
                        <div className="font-semibold tracking-wider whitespace-nowrap text-[16px] dark:text-[#FFFFFF]">Data Explorer</div>
                        <div className="text-3xl font-bold tracking-wider whitespace-nowrap  dark:text-[#FFFFFF]">
                            Select a Filter
                        </div>
                    </div>
                </div>
            </header>
            <div className="dashboard-layout__content pt-5">
                <NamedFilter onChange={handleFilterChange} />
                {!isLoading && filters && filters.length > 0 && <div className="overflow-x-auto relative rounded border border-solid border-neutral-950 border-opacity-30 pb-2">
                    <table className="min-w-full text-[12px]">
                        <thead className="bg-white dark:bg-[#0B0B0B]">
                            <tr>
                                {
                                    filters.map((filter, index) => {
                                        return <th key={filter.id} className="text-left py-1 px-2">
                                            <div className="flex gap-2 items-center sortable-column filter-column">
                                                <span className="sortable-column__text dark:text-white whitespace-nowrap">{filter.title}</span>
                                                {/* {
                                                    filter.fType === 'date' && <DatePicker
                                                        className="min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
                                                        toggleCalendarOnIconClick
                                                        showTimeSelect
                                                    />
                                                    || filter.filterable && <input
                                                        onInput={(e) => handleFilterInput(e, index)}
                                                        name={filter.title}
                                                        value={filter.filterValue}
                                                        type="text"
                                                        className="p-1 bg-white dark:bg-[#0B0B0B] rounded border border-solid border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50" />
                                                }

                                                {
                                                    filter.asc &&
                                                    <span className="cursor-pointer sortable-column__icon asc-filter size-6" onClick={() => handleascChange(index)}>
                                                        <img loading="lazy" src={formatAscValueToIcon(filter.ascValue)} alt="Sort Icon" className="size-6" />
                                                    </span>
                                                }
                                                {
                                                    filter.sortable &&
                                                    <span className="cursor-pointer sortable-column__icon sort-filter size-6" onClick={() => handleSortChange(index)}>
                                                        <img loading="lazy" src={formatSortValueToIcon(filter.sortValue)} alt="Sort Icon" className="size-6" />
                                                    </span>
                                                } */}
                                            </div>
                                        </th>
                                    })
                                }
                                <th className="text-left py-1 px-2 dark:text-white">Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                savedFilters.map((row, index) => {
                                    return <tr key={row.id} className={`${index % 2 ? 'bg-[#fbfbfb]' : 'bg-gray-100s'}  border-t border-solid border-neutral-950 border-opacity-10 text-left`}>
                                        <td className="py-1 px-2">{row.id}</td>
                                        <td className="py-1 px-2">{row.vessel.name}</td>
                                        <td className="py-1 px-2">{row.name}</td>
                                        <td className="py-1 px-2">{format(row.startDate, 'dd/MM/yy, HH:mm')}</td>
                                        <td className="py-1 px-2">{format(row.endDate, 'dd/MM/yy, HH:mm')}</td>
                                        {/* <td className="py-1 px-2">{row.end || ""}</td>
                                        <td className="py-1 px-2">
                                            {
                                                row.status && statusButtons[row.status]
                                            }
                                        </td> */}
                                        <td className="py-1 px-2">
                                            <div className="flex items-center gap-2">
                                                <NavLink to={`/data-explorer/result?vesselId=${row.vesselId}&startDate=${String(row.startDate)}&endDate=${String(row.endDate)}`} className="text-[#E42320] underline font-semibold">
                                                    Select
                                                </NavLink>
                                                <button onClick={() => handleEditFilter(row)} className='text-[#E42320] underline font-semibold'>Edit</button>
                                                <button onClick={() => handleDeleteFilter(row.id)} className='text-[#E42320] underline font-semibold'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </table>
                    {/* <div className="sticky bottom-0 left-0 bg-white dark:bg-[#0B0B0B] table-pagination-footer p-3 border-t border-solid bg-zinc-50 border-neutral-950 border-opacity-10">
                        <ul className="table-pagination flex justify-center items-center gap-2 text-[12px] font-semibold">
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">
                                    <img src={ArrowLeft} />
                                </button>
                            </li>
                            <li className="table-pagination__item active">
                                <button className="block table-pagination__button">1</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">2</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">3</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">4</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">5</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">...</button>
                            </li>
                            <li className="table-pagination__item">
                                <button className="block table-pagination__button">
                                    <img src={ArrowRight} />
                                </button>
                            </li>
                        </ul>
                    </div> */}
                </div>}

                {
                    isLoading && <div>Loading</div>
                }
                {
                    !isLoading && filters && filters.length === 0 && <div>No Filters Found</div>
                }

            </div>

            {
                filterToEdit && <EditFilterModal filter={filterToEdit} savedFilters={savedFilters} onClose={() => setFilterToEdit(null)} />
            }
        </>
    )
}

export default DataExplorer