import {Slider} from "../../components/Slider";
import {WidgetCard} from "../../components/WidgetCard";
import { ReactComponent as WindDial } from '../../assets/img/wind- dial.svg'
import { ReactComponent as Compass } from '../../assets/img/compass.svg'
import { ReactComponent as Ship } from '../../assets/img/ship.svg'
import mapBg from "../../assets/img/frame-69.jpg"
import windgetPoster from "../../assets/img/widget-poster.png"
import {useEffect, useMemo, useRef, useState} from "react";
import MapboxComponent from "../../components/MapComponent";
import ReactDatePicker from "react-datepicker";
import ExplorerMap from "../../components/ExplorerMap";
import { FilterList, SaveFilterModal } from "../../components/NamedFilter";
import { useClickOutside } from "../../helpers/useClickOutside";
import api from "../../api";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import classNames from 'classnames';
import PanelButton from "../../components/base/PanelButton";
import { ReactComponent as DownloadIcon } from "../../assets/img/icons/load.svg";
import { convertToCSV, downloadCSV } from "../../components/MissionPlanerControls";
import { saveAs } from 'file-saver'; 
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import { getFilters } from "../../store/slices/filtersSlice";
import { fetchCreateFilter, fetchDeleteFilter, fetchFilters } from "../../store/thunks/filtersThunks";
import { getSocket } from "../../store/slices/shipsSocket";

function groupByWaypoint(data) {
  const groupedData = {};

  data.forEach(item => {
    const key = item.msg_format === 1 ? `${item.target_waypoint_lat}-${item.target_waypoint_long}` : `${item.target_waypoint_latitude}-${item.target_waypoint_longitude}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(item.msg_format === 1 ? [item.longditude, item.latitude] : [item.longitude, item.latitude]);
  });

  return Object.values(groupedData);
}

export const DataExplorerMission = () => {
    const [isPopupOpened, setIsPopupOpened] = useState(false)
    const [modalData, setModalData] = useState({
        name: ''
    })
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get('startDate'); 
    const endDate = searchParams.get('endDate');
    const vessel = searchParams.get('vesselId');
    const [vesselHistory, setVesselHistory] = useState([]);
    // console.log(vesselHistory)
    const filteredHistory = vesselHistory.filter(item => item.createdAt >= startDate && item.createdAt <= endDate)
    console.log(filteredHistory)
    const uniquePoints = Array.from(new Set(filteredHistory.map(item => {
      if(item.target_waypoint_long) return item.target_waypoint_long + ',' + item.target_waypoint_lat
      return item.target_waypoint_longitude + ',' + item.target_waypoint_latitude
    }))).map(coordString => coordString.split(',').map(Number));
    // console.log(uniquePoints, 'unique')
    const points = uniquePoints.map((item, index) => ({
        coordinates: item,
        // name: 'Point A',
        hidden: false,
        count: false,
        number: 0,
        numberOn: index + 1,
    }))
   const allCoords = filteredHistory.map(item => ([item.longditude, item.latitude]))
   const groupedData = groupByWaypoint(filteredHistory);
   // console.log(groupedData, 'groupedData')
  const mapLabels = filteredHistory.map(item => format(item.createdAt, 'dd/MM/yy, HH:mm'))
  const timescale = filteredHistory.map(item => format(item.createdAt, 'HH:mm'))
  const groups = [
    [
      {
        key: 'instantaneous_sail_setting_',
        label: 'instantaneous_sail_setting_'
      },
      {
        key: 'average_absolute_rudder_angle',
        label: 'average_absolute_rudder_angle'
      },
    ],
    [
      {
        key: 'sd_error',
        label: 'sd_error'
      },
      {
        key: 'gps_comms_error',
        label: 'gps_comms_error'
      },
      {
        key: 'power_sensor_comms_error',
        label: 'power_sensor_comms_error'
      },
      {
        key: 'heading_sensor_value_error',
        label: 'heading_sensor_value_error'
      },
      {
        key: 'temperature_sensor_1_comms_error',
        label: 'temperature_sensor_1_comms_error'
      },
      {
        key: 'first_message_since_reset',
        label: 'first_message_since_reset'
      },
      {
        key: 'gps_fix_error',
        label: 'gps_fix_error'
      },
      {
        key: 'wind_sensor_comms_error',
        label: 'wind_sensor_comms_error'
      },
      {
        key: 'heading_sensor_comms_error',
        label: 'heading_sensor_comms_error'
      },
      {
        key: 'aux_mcu_comms_error',
        label: 'aux_mcu_comms_error'
      },
      {
        key: 'temperature_sensor_2_comms_error',
        label: 'temperature_sensor_2_comms_error'
      },
      
    ],
    [
      {
        key: 'navigator_wind_speed_average',
        label: 'navigator_wind_speed_average'
      },
    ],
    [
      {
        key: 'navigator_wind_direction_average',
        label: 'navigator_wind_direction_average'
      },
      {
        key: 'navigator_wind_direction_lower_extremum',
        label: 'navigator_wind_direction_lower_extremum'
      },
      {
        key: 'navigator_wind_direction_upper_extremum',
        label: 'navigator_wind_direction_upper_extremum'
      },
      {
        key: 'instantaneous_navigator_wind_direction',
        label: 'instantaneous_navigator_wind_direction'
      },
    ],
    [
      {
        key: 'peak_gps_speed',
        label: 'peak_gps_speed'
      },
      {
        key: 'average_gps_speed',
        label: 'average_gps_speed'
      },
      {
        key: 'average_speed_on_target_heading',
        label: 'average_speed_on_target_heading'
      },
      {
        key: 'average_speed_towards_target_waypoint',
        label: 'average_speed_towards_target_waypoint'
      },
    ],
    [
      {
        key: 'rudder_duty_cycle_average',
        label: 'rudder_duty_cycle_average'
      },
      {
        key: 'sail_duty_cycle_average',
        label: 'sail_duty_cycle_average'
      },
    ],
    [
      {
        key: 'battery_voltage_average',
        label: 'battery_voltage_average'
      },
      {
        key: 'battery_voltage_minimum',
        label: 'battery_voltage_minimum'
      },
    ],
    [
      {
        key: 'average_power_consumption',
        label: 'average_power_consumption'
      },
    ],
    [
      {
        key: 'sail_encoder_error',
        label: 'sail_encoder_error'
      },
      {
        key: 'sail_stall_detected',
        label: 'sail_stall_detected'
      },
      {
        key: 'rudder_encoder_error',
        label: 'rudder_encoder_error'
      },
      {
        key: 'rudder_stall_detected',
        label: 'rudder_stall_detected'
      },
    ],
    [
      {
        key: 'experiment_1',
        label: 'experiment_1'
      },
      {
        key: 'experiment_2',
        label: 'experiment_2'
      },
    ],
    [
      {
        key: 'target_heading',
        label: 'target_heading'
      },
      {
        key: 'average_heading_error',
        label: 'average_heading_error'
      },
      {
        key: 'navigator_wind_direction_average',
        label: 'navigator_wind_direction_average'
      },
      {
        key: 'direct_bearing_to_waypoint',
        label: 'direct_bearing_to_waypoint'
      },
      {
        key: 'average_absolute_heading_error',
        label: 'average_absolute_heading_error'
      },
      {
        key: 'average_course_over_ground',
        label: 'average_course_over_ground'
      },
    ],
    [
      {
        key: 'cross_track_error',
        label: 'cross_track_error'
      },
      {
        key: 'tacking_corridor_width',
        label: 'tacking_corridor_width'
      },
    ],
    [
      {
        key: 'distance_to_waypoint',
        label: 'distance_to_waypoint'
      },
    ],
    [
      {
        key: 'active_waypoint_index',
        label: 'active_waypoint_index'
      },
    ],
    [
      {
        key: 'stationkeeping_active',
        label: 'stationkeeping_active'
      },
    ],
    [
      {
        key: 'tacking_corridor_width',
        label: 'tacking_corridor_width'
      },
      {
        key: 'target_waypoint_radius',
        label: 'target_waypoint_radius'
      },
    ],
    [
      {
        key: 'waypoint_in_nogo_flag',
        label: 'waypoint_in_nogo_flag'
      },
      {
        key: 'target_tack_setting',
        label: 'target_tack_setting'
      },
    ],
    [
      {
        key: 'target_waypoint_longitude',
        label: 'target_waypoint_longitude'
      },
      {
        key: 'longitude',
        label: 'longitude'
      },
      {
        key: 'tack_corridor_start_longitude',
        label: 'tack_corridor_start_longitude'
      },
    ],
    [
      {
        key: 'total_amp_hour_consumption',
        label: 'total_amp_hour_consumption'
      },
    ],
    [
      {
        key: 'target_waypoint_latitude',
        label: 'target_waypoint_latitude'
      },
      {
        key: 'latitude',
        label: 'latitude'
      },
      {
        key: 'tack_corridor_start_latitude',
        label: 'tack_corridor_start_latitude'
      },
    ],
    [
      {
        key: 'control_mode_',
        label: 'control_mode_'
      },
      {
        key: 'sail_lut_mode_',
        label: 'sail_lut_mode_'
      },
    ],
    [
      {
        key: 'average_sea_surface_temperature_1',
        label: 'average_sea_surface_temperature_1'
      },
      {
        key: 'average_sea_surface_temperature_2',
        label: 'average_sea_surface_temperature_2'
      },
    ],
    [
      {
        key: 'tack_counter',
        label: 'tack_counter'
      },
    ],
    [
      {
        key: 'average_barometric_pressure',
        label: 'average_barometric_pressure'
      },
    ],
    [
      {
        key: 'average_relative_humidity',
        label: 'average_relative_humidity'
      },
    ],
    [
      {
        key: 'average_air_temperature',
        label: 'average_air_temperature'
      },
    ],
    [
      {
        key: 'avg_wind_speed',
        label: 'avg_wind_speed'
      },
      {
        key: 'peak_wind_speed',
        label: 'peak_wind_speed'
      },
    ],
    [
      {
        key: 'avg_wind_direction',
        label: 'avg_wind_direction'
      },
    ],
  ]

  const graph_colors = [
    {
      borderColor: '#2086E4',
      backgroundColor: '#2086E440',
    },
    {
      borderColor: '#50B6F0',
      backgroundColor: '#50B6F040',
    },
    {
      borderColor: '#00B1D8',
      backgroundColor: '#00B1D840',
    },
    {
      borderColor: '#536DCA',
      backgroundColor: '#536DCA40',
    },
    {
      borderColor: '#74C7FC',
      backgroundColor: '#74C7FC40',
    },
    {
        borderColor: '#2196F3',
        backgroundColor: '#2196F340',
    }
  ]

  const chartData = groups.map(item => ({
    data: {
        labels: mapLabels,
        datasets: item.map(({key, label}, index) => ({
          label,
          data: filteredHistory.map(item => item[key]),
          fill: false,
          borderWidth: 3,
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          tension: 0.3,
          ...graph_colors[index]
      }))
    },
    options: {
        plugins: {
            htmlLegend: {
                containerID: '10',
                template: function ({ item, value, chart }) {
                    return (
                        `
                            <p className="legend__label">
                                <span className="legend__icon" style="background-color: ${item.fillStyle}25; border: 1px solid ${item.strokeStyle}"></span>
                              
                                <span className="info">${item.text}</span>
                            </p>
                        `
                    )
                }
            },

        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                },
            },
            y: {
                // min: 0,
                // max: 10,

                grid: {
                    borderDash: [8, 4],
                },
                beginAtZero: true,
                ticks: {
                    stepSize: 2.5,
                    callback: function(value) {
                        return `${value}`;
                    },
                },
            },
        },

    }
  }))
   
    useEffect(() => {
      if(!vessel) return
      setIsLoading(true)
      api.fleet.getOneHistoryVessel(vessel).then(response => {
        setIsLoading(false)
        if(!response.success) {
          return
        }
        setVesselHistory(response.data.map(item => {
          if(item?.payload) {
            const result = {...item, ...item.payload}
            delete result.payload
            return result
          } else {
            return item
          }
        }).reverse())
        // const result = response.data.filter(item => item.createdAt > )
      }).catch(err => console.error(err))
    }, [vessel])
    const [isLoading, setIsLoading] = useState(false)
    const pointClickCallback = () => {
        setIsPopupOpened(true)
    }
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    // console.log(startDate, endDate)
    const onChange = (date, field) => {
        // const [start, end] = dates;
        // console.log(start, end)
        const newSearchParams = new URLSearchParams(searchParams.toString());
            // start ? newSearchParams.set('startDate', start.toISOString()) : newSearchParams.delete('startDate')
            // end ? newSearchParams.set('endDate', end.toISOString()) : newSearchParams.delete('endDate');
            newSearchParams.set(field, date?.toISOString() || null)
            // newSearchParams.set('endDate', end?.toISOString() || null)
            setSearchParams(newSearchParams);
        // if (start && end && start > end) {
        //     // Если начальная дата больше конечной, устанавливаем начальную дату равной конечной
        //     const newSearchParams = new URLSearchParams(searchParams.toString());
        //     end && newSearchParams.set('startDate', end.toISOString())
        //     end && newSearchParams.set('endDate', end.toISOString());
        //     setSearchParams(newSearchParams);
        //   } else {
        //     const newSearchParams = new URLSearchParams(searchParams.toString());
        //     start ? newSearchParams.set('startDate', start.toISOString()) : newSearchParams.delete('startDate')
        //     end ? newSearchParams.set('endDate', end.toISOString()) : newSearchParams.delete('endDate');
        //     setSearchParams(newSearchParams);
        //   }
        
    };
  const [time, setTime] = useState(20);
  const filters = useSelector(getFilters)
  const dispatch = useDispatch()
  const handleFilterDelete = (id) => {
    dispatch(fetchDeleteFilter(id))
    
    if (selectedFilter && (selectedFilter.id === id)) {
      setSelectedFilter(null)
    }
  };
  const [selectedFilter, setSelectedFilter] = useState(null);
  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
  };
  useEffect(() => {
    if(!selectedFilter) return
    setSearchParams({
        vesselId: selectedFilter.vesselId,
        startDate: selectedFilter.startDate,
        endDate: selectedFilter.endDate
    })
  }, [selectedFilter])
  
  const handleFilterClear = () => {
    setSelectedFilter(null);
  };
  const [vesselsDropdownOpen, setVesselsDropdownOpen] = useState(false);
  const [allVessels, setAllVessel] = useState([]);
  const getAllFleets = async () => {
    let res = await api.fleet.getAllVessel();
    setAllVessel(res.data);
  }
  useEffect(() => {
    getAllFleets();
    dispatch(fetchFilters())
  }, []);
  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setVesselsDropdownOpen(false));
  const handleVesselSelect = (vessel) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('vesselId', vessel || null)
    setSearchParams(newSearchParams);
    setVesselsDropdownOpen(false);
  }
  const [filtersToSave, setFiltersToSave] = useState(null);
    const [selectedSliderDate, setSelectedSliderDate] = useState(null)
  // const heading = filteredHistory?.[filteredHistory.length -1]?.target_heading || 0


    const closestDateObject = useMemo(() => {
        if (!filteredHistory || !selectedSliderDate || !filteredHistory.length) return null;
        return findClosestDate(filteredHistory, selectedSliderDate);
    }, [filteredHistory, selectedSliderDate]);


    const heading = closestDateObject ? closestDateObject.target_heading : 0
    
    const marker = {
        coordinates: [filteredHistory?.[filteredHistory.length -1]?.longditude || filteredHistory?.[filteredHistory.length -1]?.longitude, filteredHistory?.[filteredHistory.length -1]?.latitude],
        target_heading: filteredHistory?.[filteredHistory.length -1]?.target_heading 
    }
    // console.log(marker, 'MArker')
    const elementRef = useRef(null);
    const handleDownloadImage = () => {
      
      html2canvas(elementRef.current)
        .then((canvas) => {
          const dataURL = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.href = dataURL;
          link.download   
   = 'my-image.png';
          link.click();
        });
    };



    const [formattedDate, setFormattedDate] = useState('');
    const getDateFromSliderValue = (value, startDate, endDate) => {

        const start = new Date(startDate).getTime()
        const end = new Date(endDate).getTime()
        const timeRange = end - start
        const timeAtValue = start + (value / 100) * timeRange

        return new Date(timeAtValue)
    };


    const [sliderVal, setSliderVal] = useState(0)

    const handleSliderChange = () => {
        const selectedDate = getDateFromSliderValue(sliderVal, startDate, endDate);
        setSelectedSliderDate(selectedDate)
        const formatted = format(selectedDate, 'yyyy-MM-dd HH:mm:ss')


        setFormattedDate(formatted)
    };

    useEffect(() => {
        handleSliderChange()
    }, [sliderVal]);

    useEffect(() => {
        setSliderVal(0)
    }, [startDate, endDate]);


    function findClosestDate(datesArray, targetDate) {
        const targetTime = new Date(targetDate).getTime();

        return datesArray.reduce((closest, current) => {
            const currentTime = new Date(current.createdAt).getTime();
            return Math.abs(currentTime - targetTime) < Math.abs(new Date(closest.createdAt).getTime() - targetTime)
                ? current
                : closest;
        });
    }
    const socket = useSelector(getSocket)
    useEffect(() => {
      if (socket) {
          
          socket.on('vessel-new-waypoint', (data) => {
              if(data?.vessel?.id !== Number(vessel)) {
                return
              }
              const actual = Array.isArray(data) ? data[0] : data?.waypoint
              // console.log(actual)
              const result = {...actual, ...actual.payload}
              delete result.payload
              if(result.msg_format === 2) {
                  result.longditude = result.longitude
              }
              setVesselHistory(prev => ([...prev, result]))
              // setVesselData(prev => ([...prev, actual]))
             
          });
      }
  }, [socket])
    
    
    console.log(closestDateObject, 'closestDateObject')
    
    return (
        <>
            <header className="widget-card bg-[#FBFBFB] dark:bg-[#1B1B1B] flex gap-5 justify-between p-[16px] w-full shadow-sm border border-solid border-red-600 border-opacity-30 max-md:flex-wrap max-md:max-w-full rounded-[4px]">
                <div className="flex gap-2 justify-between max-md:flex-wrap w-full">
                    <div className="flex items-center w-full flex-wrap justify-center gap-2">
                    <div className="flex items-center flex-wrap xl:flex-nowrap gap-[8px]">
                        <FilterList
                            filters={filters}
                            deleteFilter={handleFilterDelete}
                            selectedFilter={selectedFilter}
                            onFilterSelect={handleFilterSelect}
                            onFilterClear={handleFilterClear}
                        /> 
                            
                            <div className='flex flex-col'>
          <ReactDatePicker
            className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
            // className="px-2 min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
            toggleCalendarOnIconClick
            showTimeSelect
            selected={new Date(startDate)}
            placeholderText="Start Date"
            onChange={(date) => onChange(date, 'startDate')}
            required
            dateFormat="dd/MM/yy, HH:mm"
            popperClassName="!z-[100]"
          />
        </div>
        <div className='flex flex-col'>
          <ReactDatePicker
            className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
            // className="px-2 min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
            toggleCalendarOnIconClick
            showTimeSelect
            selected={new Date(endDate)}
            placeholderText="End Date"
            onChange={(date) => onChange(date, 'endDate')}
            required
            dateFormat="dd/MM/yy, HH:mm"
            popperClassName="!z-[100]"
            forma
          />
        </div>
                          
                            {/* <svg className="input-svg-right" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 19H5V8H19M16 1V3H8V1H6V3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H18V1M17 12H12V17H17V12Z" fill="currentColor">
                                </path>
                            </svg> */}
                     

                        {/* <div className="input-group relative text-[#0B0B0B80]">
                            <div className="react-datepicker-wrapper">
                                <div className="react-datepicker__input-container">
                                    <input type="text" placeholder="Select Trial" className="map-side-input map-side-input-picker text-[12px] !w-[158px] py-[7px]" />
                                </div>
                            </div>
                            <svg className="input-svg-right" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.7112 6.70087C11.5044 22.9825 15.3408 6.38887 5.86681 14.3293L8.02081 22.8001H5.59801L1.20001 5.50807L3.42001 4.71127C14.0388 -3.05393 8.49361 11.6953 22.1844 6.21607C22.6212 6.03967 22.9704 6.32407 22.7112 6.70087Z" fill="currentColor"></path>
                            </svg>
                        </div> */}
                        
                            <div className='flex flex-col col-span-2'>
          <div className="relative" ref={dropdownRef}>
            <div
              onClick={() => setVesselsDropdownOpen(!vesselsDropdownOpen)}
            >
              <input
                value={allVessels.find(item => item.id === Number(vessel))?.name || ''}
                onChange={(e) => {
                    const newSearchParams = new URLSearchParams(searchParams.toString());
                    newSearchParams.set('vesselId', e.target.value || null)
                    setSearchParams(newSearchParams);
                }}
                type="text"
                required
                placeholder='Vessel name'
                className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
              />
            </div>
            {vesselsDropdownOpen && (
              <ul className="absolute flex flex-col right-[-1px] top-[calc(100%+2px)] w-full rounded-b-[2px] rounded-t-0 border-t-0 border border-[#0B0B0B1A] bg-white min-w-[calc(100%+2px)] z-10 max-h-[180px] overflow-y-auto">
                {allVessels.length ? allVessels.map((vessel, index) => (
                  <li key={index} className="flex items-center gap-3 hover:bg-slate-50 p-2">
                    <div onClick={() => handleVesselSelect(vessel.id)} className="flex-auto truncate cursor-pointer">
                      {vessel.name}
                    </div>
                  </li>
                )) : (
                  <li className='flex items-center gap-3 text-center p-2'>
                    There is no saved filters yet
                  </li>
                )}
              </ul>
            )}
          </div>
          
        </div>
        <button
          disabled={!(vessel && startDate && endDate)}
          onClick={() => {
            setFiltersToSave({ vessel: Number(vessel), startDate, endDate });
          }}
          className={`${!(vessel && startDate && endDate) ? "opacity-30" : ""} flex items-center justify-center gap-1 font-semibold h-8 px-3 text-xs tracking-[4%] rounded border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917] w-max`}
        >
          Save Filter
        </button>
                    </div>
                    {filtersToSave && (
        <SaveFilterModal
          filters={filtersToSave}
          onSubmit={(newFilter) => {
            dispatch(fetchCreateFilter({
              name: newFilter.name,
              startDate: newFilter.startDate,
              endDate: newFilter.endDate,
              vesselId: newFilter.vessel
            }))
          }}
          onClose={() => setFiltersToSave(null)}
        />
        
      )}
      <button disabled={!filteredHistory.length} className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]" onClick={() => {
                                // const transformedObject = transformObjectKeys(currentMission);
                                const csvString = convertToCSV(filteredHistory);
                                
                                downloadCSV(csvString, `data-explorer-${format(startDate, 'dd/MM/yy, HH:mm')}-${format(endDate, 'dd/MM/yy, HH:mm')}.csv`);
                            }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 16.5L7 11.5L8.4 10.05L11 12.65V4.5H13V12.65L15.6 10.05L17 11.5L12 16.5ZM4 20.5V15.5H6V18.5H18V15.5H20V20.5H4Z" fill="currentColor"/>
                                </svg>
                            </button>
                        <div className="slider-connect-wrap flex items-center gap-[8px] flex-auto w-full flex-nowrap border-[#0B0B0B40] xl:px-[16px]  py-[8px] overflow-hidden">
                            <div className="w-full">
                                <Slider
                                    start={sliderVal}
                                    // classOverride='red-slider-custom'
                                    // onChange={(values) => setTime(Math.round(Number(values[0]) * 60 / 100))}
                                    onChange={(values) => setSliderVal(+values[0])}
                                 />
                            </div>
                            {/*<span className="block text-[12px] min-w-max">25/01/24 - 13:{String(time).length < 2 && '0'}{time}:48</span>*/}
                            <span className="block text-[12px] min-w-max">{formattedDate}</span>
                        </div>
                        <div className="flex items-center gap-[8px] flex-wrap xl:flex-nowrap">
                            {/* <button onClick={() => {
                              handleDownloadImage()
                            }} className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.66667 16.4444H17.3333L14 12L11.3333 15.5556L9.33333 12.8889L6.66667 16.4444ZM4 20V4H20V20H4Z" fill="currentColor" />
                                </svg>
                            </button>
                            
                            <button className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.3707 4.888 14.112C3.63 12.8533 3.00067 11.316 3 9.5C3 7.68333 3.62933 6.146 4.888 4.888C6.14667 3.63 7.684 3.00067 9.5 3C11.3167 3 12.8543 3.62933 14.113 4.888C15.3717 6.14667 16.0007 7.684 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5633 11.8133 14.0007 10.7507 14 9.5C14 8.25 13.5627 7.18767 12.688 6.313C11.8133 5.43833 10.7507 5.00067 9.5 5C8.25 5 7.18767 5.43767 6.313 6.313C5.43833 7.18833 5.00067 8.25067 5 9.5C5 10.75 5.43767 11.8127 6.313 12.688C7.18833 13.5633 8.25067 14.0007 9.5 14Z" fill="currentColor" />
                                    <path d="M9 7H10V9H12V10H10V12H9V10H7V9H9V7Z" fill="currentColor" />
                                </svg>
                            </button>
                            <button className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20L9.16664 17.1667L10.1166 16.2167L11.3333 17.4333V12.6667H6.58334L7.80001 13.8667L6.83334 14.8333L4 12L6.81667 9.18334L7.76667 10.1333L6.56667 11.3333H11.3333V6.56667L10.1166 7.78333L9.16664 6.83333L12 4L14.8333 6.83333L13.8833 7.78333L12.6666 6.56667V11.3333H17.4167L16.2 10.1333L17.1667 9.16667L20 12L17.1667 14.8333L16.2167 13.8833L17.4333 12.6667H12.6666V17.4167L13.8666 16.2L14.8333 17.1667L12 20Z" fill="currentColor" />
                                </svg>
                            </button>
                            <button className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 10V14H14V20H10L10 14H4V10H10V4L14 4L14 10H20Z" fill="currentColor" />
                                </svg>
                            </button>
                            <button className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 10H20V14H4V10Z" fill="currentColor" />
                                </svg>
                            </button>
                            <button className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6L8.84749 6.72523L8.18067 7.35906L7.37726 8.12272L6.76298 8.7066L6 6Z" fill="currentColor" />
                                    <path d="M6 18L6.72523 15.1525L7.35906 15.8193L8.12272 16.6227L8.7066 17.237L6 18Z" fill="currentColor" />
                                    <path d="M18 18L15.1525 17.2748L15.8193 16.6409L16.6227 15.8773L17.237 15.2934L18 18Z" fill="currentColor" />
                                    <path d="M18 6L17.2748 8.84749L16.6409 8.18067L15.8773 7.37726L15.2934 6.76298L18 6Z" fill="currentColor" />
                                    <path d="M4 20L4 19.3333L4 17.3333H4.66667L4.66667 19.3333H6.66667L6.66667 20H4.66667H4Z" fill="currentColor" />
                                    <path d="M9.15711 10L10 9.27798L8.18067 7.35906L7.37726 8.12272L9.15711 10Z" fill="currentColor" />
                                    <path d="M14 9.15711L14.722 10L16.6409 8.18067L15.8773 7.37726L14 9.15711Z" fill="currentColor" />
                                    <path d="M14.8429 14L14 14.722L15.8193 16.6409L16.6227 15.8773L14.8429 14Z" fill="currentColor" />
                                    <path d="M10 14.8429L9.27798 14L7.35906 15.8193L8.12272 16.6227L10 14.8429Z" fill="currentColor" />
                                    <path d="M20 20H19.3333H17.3333V19.3333H19.3333V17.3333H20V19.3333V20Z" fill="currentColor" />
                                    <path d="M20 4V4.66667V6.66667H19.3333V4.66667L17.3333 4.66667V4L19.3333 4L20 4Z" fill="currentColor" />
                                    <path d="M4 4L4.66667 4L6.66667 4V4.66667H4.66667L4.66667 6.66667H4L4 4.66667V4Z" fill="currentColor" />
                                </svg>
                            </button>
                            <button className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14 4H20V10L18 8L14 12L12 10L16 6L14 4ZM10 20H4V14L6 16L10 12L12 14L8 18L10 20Z" fill="currentColor" />
                                </svg>
                            </button>
                            <button className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 3H3V21H21V12H19V19H5L5 5H12V3Z" fill="currentColor" />
                                    <path d="M22 2H16L18 4L9 13L11 15L20 6L22 8V2Z" fill="currentColor" />
                                </svg>
                            </button> */}
                            {/* <button disabled={!filteredHistory.length} className="tool-btn text-[#0B0B0B]/[.50] p-[2px] hover:text-[#E42320] hover:bg-[#E42320]/[.25] rounded-[360px]" onClick={() => {
                                // const transformedObject = transformObjectKeys(currentMission);
                                const csvString = convertToCSV(filteredHistory);
                                
                                downloadCSV(csvString, `data-explorer-${format(startDate, 'dd/MM/yy, HH:mm')}-${format(endDate, 'dd/MM/yy, HH:mm')}.csv`);
                            }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 16.5L7 11.5L8.4 10.05L11 12.65V4.5H13V12.65L15.6 10.05L17 11.5L12 16.5ZM4 20.5V15.5H6V18.5H18V15.5H20V20.5H4Z" fill="currentColor"/>
                                </svg>
                            </button> */}
                        </div>
                    </div>
                </div>
            </header>
            {isLoading ? <p className="p-4 text-center text-4xl">Loading...</p> : filteredHistory.length < 2 ? <p className="p-4 text-center text-4xl">There is no data for selected parametrs</p> :
            <div className="flex flex-auto gap-[12px] max-xl:flex-col">
                <div ref={elementRef} className="dashboard-layout__content w-full overflow-auto flex flex-col xl:flex-row pt-5 gap-[12px]">
                    <WidgetCard data={chartData} timescale={timescale} startDate={startDate} endDate={endDate}/>
                </div>
                <div className="pt-5 flex flex-col gap-[12px] max-xl:flex-row">
                    <div style={{maxHeight: '550px'}} className="flex-1 w-[397px] h-[383px] widget-card relative rounded border border-solid shadow-sm bg-[#FBFBFB] border-red-600 border-opacity-30  dark:bg-[#1B1B1B] dark:text-white">
                        {/* <div className="widget-inner flex flex-column cursor-pointer" onClick={() => setIsPopupOpened(true)}>
                            <img src={mapBg} className="w-full object-cover" />
                        
                            </div> */}
                        <ExplorerMap 
                          pointClickCallback={pointClickCallback}
                          historyPoints={filteredHistory}
                          closestDateObject={closestDateObject}
                        />
                        {isPopupOpened && <div className="z-10 widget-card window-card border border-solid shadow-sm bg-[#FBFBFB] border-red-600 border-opacity-30 dark:bg-[#1B1B1B] rounded-[4px]">
                            <div className="flex items-center justify-between mb-[4px]">
                                <span>IMG.png</span>
                                <button onClick={() => setIsPopupOpened(false)}>
                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.77295 9.47699C9.87862 9.58266 9.93799 9.72599 9.93799 9.87543C9.93799 10.0249 9.87862 10.1682 9.77295 10.2739C9.66728 10.3795 9.52395 10.4389 9.37451 10.4389C9.22507 10.4389 9.08175 10.3795 8.97607 10.2739L5.99998 7.29683L3.02295 10.2729C2.91728 10.3786 2.77395 10.438 2.62451 10.438C2.47507 10.438 2.33175 10.3786 2.22607 10.2729C2.1204 10.1673 2.06104 10.0239 2.06104 9.87449C2.06104 9.72505 2.1204 9.58173 2.22607 9.47605L5.2031 6.49996L2.22701 3.52293C2.12134 3.41726 2.06197 3.27393 2.06197 3.12449C2.06197 2.97505 2.12134 2.83173 2.22701 2.72605C2.33268 2.62038 2.47601 2.56102 2.62545 2.56102C2.77489 2.56102 2.91821 2.62038 3.02389 2.72605L5.99998 5.70309L8.97701 2.72558C9.08268 2.61991 9.226 2.56055 9.37545 2.56055C9.52489 2.56055 9.66821 2.61991 9.77389 2.72558C9.87956 2.83126 9.93892 2.97458 9.93892 3.12402C9.93892 3.27347 9.87956 3.41679 9.77389 3.52246L6.79685 6.49996L9.77295 9.47699Z" fill="currentColor"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="w-[168px] h-[114px]">
                                <img src={windgetPoster} className="w-full h-full object-cover"/>
                            </div>
                        </div>}
                    </div>
                    <div style={{maxHeight: '550px'}} className="flex-1 relative rounded-[4px] border border-[#E4232040] border-solid p-[16px] bg-[#8EB4E5] w-[397px] h-[383px] flex flex-col justify-between">
                        <div className="flex items-center self-end">
                            <div style={{transform: `rotate(${closestDateObject?.avg_wind_direction}deg)`}}>
                                <WindDial />
                            </div>
                            <div className='relative'>
                                <Compass/>
                                <span style={{position: 'absolute', top: '50%', left: '50%', transform: `translate(-50%, -50%) rotate(${heading}deg)`}}>
                                    <svg width="96" height="96" viewBox="0 0 96 96" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M48 37L56 55L48 49L40 55L48 37Z" fill="#E42320"/>
                                    </svg>
                                </span>
                            </div>

                        </div>
                        <Ship className={classNames("absolute top-[75px] left-[36px]")}
                              style={{transform: `rotate(${heading - 45}deg)`}}/>
                        <div className="flex gap-[12px]">
                            <div className="flex-1 flex flex-col items-center justify-center h-[50px] bg-[#FBFBFB] border border-solid border-[#E4232040] rounded-[4px]">
                                <p className="text-[12px] text-[#0B0B0B80] font-semibold">Rudder Angle (°)</p>
                                <p className="text-[12px] text-[#0B0B0B] font-normal">{closestDateObject?.average_absolute_rudder_angle}</p>
                            </div>
                            <div className="flex-1 flex flex-col items-center justify-center h-[50px] bg-[#FBFBFB] border border-solid border-[#E4232040] rounded-[4px]">
                                <p className="text-[12px] text-[#0B0B0B80] font-semibold">Heading (°)</p>
                                <p className="text-[12px] text-[#0B0B0B] font-normal">{heading}</p>
                            </div>
                            <div className="flex-1 flex flex-col items-center justify-center h-[50px] bg-[#FBFBFB] border border-solid border-[#E4232040] rounded-[4px]">
                                <p className="text-[12px] text-[#0B0B0B80] font-semibold">Sail Angle (°)</p>
                                <p className="text-[12px] text-[#0B0B0B] font-normal">{closestDateObject?.sail_default_angle_of_attack}</p>
                            </div>
                        </div>
                
                    </div>
                </div>
            </div>
            }


        </>
    )
}

export default DataExplorerMission;