import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../api";


export const fetchFilters = createAsyncThunk(
    '/get-filters/',
    async (_, { rejectWithValue, abort }) => {
        try {
            const res = await api.filters.getAllFilters();
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchCreateFilter = createAsyncThunk(
    '/create-filter/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const res = await api.filters.createFilter(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchUpdateFilter = createAsyncThunk(
    '/update-filter/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const res = await api.filters.updateFilter(payload.payload, payload.filterId);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchDeleteFilter = createAsyncThunk(
    '/delete-filter/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const res = await api.filters.deleteFilter(payload);
            if (res.success) {
                return payload;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);
