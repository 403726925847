import React, { useState, useEffect } from 'react';
import { Slider } from './Slider';


const TimelineSimulationNew = ({ currentMission, sliderPercentage, setSliderPercentage, windy, callBackSliderDate }) => {

    const countEndDate = (start) => {
        const date = new Date(start)
        date.setUTCDate(date.getUTCDate() + 6)
        return date.toISOString()
    };

    const startDateProp = currentMission?.start ? new Date(currentMission.start) : new Date();

    const endDateProp = currentMission?.end ? new Date(currentMission.end) : new Date(countEndDate(startDateProp));

    
    // const playLine = [
    //     {
    //         date: startDateProp.toLocaleDateString(),
    //         day: String(startDateProp.toTimeString()).slice(0, 5)
    //     },
    //
    //     {
    //         date: endDateProp.toLocaleDateString(),
    //         day: String(endDateProp.toTimeString()).slice(0, 5)
    //     }
    // ];

    const getDaysArray = function(s,e) {
        for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){
            a.push(new Date(d));
        } return a;
    };
    
    const percentageBreakpoints = [0]

    const timeline = getDaysArray(startDateProp, endDateProp).map((dateItem, index)=>{
        const itemPrevPercentage = index > 0 ? Math.floor((100 / getDaysArray(startDateProp, endDateProp).length) * index) : 0;
        const itemPercentage = Math.floor((100 / getDaysArray(startDateProp, endDateProp).length) * (index+1));

        function range(start, end, step = 1) {
            const len = Math.floor((end - start) / step) + 1
            return Array(len).fill().map((_, idx) => start + (idx * step))
        }
        
        percentageBreakpoints.push(itemPercentage)

        return {
            index,
            date: dateItem.toDateString(),
            day: new Date().toLocaleDateString() === dateItem.toLocaleDateString() ? `(Today)` : `(${dateItem.toLocaleDateString('en', { weekday: 'long' })})`,
            percentage: itemPercentage,
            times: new Array(24).fill('').map((timeItem, timeIndex) => {
                return {
                    time: `${timeIndex < 10 ? '0'+timeIndex : timeIndex}:00`,
                    percent: Math.ceil(range(itemPrevPercentage, itemPercentage, (itemPercentage - itemPrevPercentage) / 24)[timeIndex])
                }
            }),
        }
    });
    // console.log(timeline, percentageBreakpoints)
    useEffect(() => {
        // setSliderPercentage(timeline.find(item => item.day === '(Today)')?.percentage)
    }, [])

    const getDateFromSliderValue = (value, startDate, endDate) => {
        const start = new Date(startDate).getTime()
        const end = new Date(endDate).getTime()
        const timeRange = end - start
        const timeAtValue = start + (value / 100) * timeRange
        return new Date(timeAtValue)
    };



    
    
    useEffect(() => {
        const findTimestamp = (val) => {
            percentageBreakpoints.forEach((value, index) => {
                // console.log(value)
                const prevValue = percentageBreakpoints[index - 1] || 0
                if (prevValue <= val && value >= val ) {
                    const el = timeline?.find(el => el.percentage === value)
                    if (el) {
                        console.log(el)
                        if (windy) {
                            const a = new Date(el.date).getTime()
                            windy.store.set('timestamp', a)
                        }
                    }

                }
            })
        }

        callBackSliderDate && callBackSliderDate(getDateFromSliderValue(sliderPercentage, startDateProp, endDateProp))

        findTimestamp(sliderPercentage)
    },[sliderPercentage])
    

    // const [startDate, setStartDate] = useState(null);

    // const dateOnChange = (date) => {
    //     setStartDate(date);
    //
    //     const findedeData = timeline?.find(item => new Date(item.date).toDateString() === new Date(date).toDateString());
    //
    //     setSliderPercentage(findedeData.index === 0 ? 0 : findedeData.percentage );
    // }

    // const timeOnChange = (date) => {
    //     setStartDate(date);
    //
    //     const findedeData = timeline?.find(item => new Date(item.date).toDateString() === new Date(date).toDateString());
    //     const findedeDataTime = findedeData.times.find(item => item.time === new Date(date).toTimeString().slice(0,5));
    //
    //     setSliderPercentage(findedeDataTime?.percent);
    // }

    // const [isSimulating, setIsSimulating] = useState(false);


    // const simulateStop = () => {
    //     setPlayerStatus("stop");
    //     clearInterval(playInterval);
    //     setIntervalStarted(false);
    //     document.querySelector(':root').style.setProperty('--player-transition', '0s');
    // }


    // const timerInterval = ((wayLength / (1.85 * knots)) / 60).toFixed(1) * 1000;

    // useEffect(() => {
    //     if(playerStatus === "play" && !intervalStarted){
    //         document.querySelector(':root').style.setProperty('--player-transition', `${timerInterval/1000}s`);
    //
    //         setPlayInterval(setInterval(() => {
    //             setIntervalStarted(true);
    //
    //             setSliderPercentage((prev) => {
    //                 if(prev < 100){
    //                     return prev+1;
    //                 }
    //             });
    //
    //         }, timerInterval));
    //     } else if(intervalStarted){
    //         simulateStop();
    //     }
    // }, [playerStatus]);

    // useEffect(() => {
    //     if(sliderPercentage > 99 && playInterval && intervalStarted){
    //         simulateStop();
    //         // setIsSimulating(false);
    //         return;
    //     }
    // }, [sliderPercentage]);

    return <div className='h-[132px] shadow-[0px_4px_8px_0px_#0000001A] border border-[#E4232040] rounded-[4px] p-[8px] bg-[#FBFBFB]' key={"isNotSimulating"}>
            <div className='rounded-[4px] border border-[#0B0B0B1A] bg-[#EFF2F4] p-[4px] flex items-center gap-[8px] min-h-[117px]'>
                <div className='h-full flex-1'>
                    <ul className='flex items-center justify-between'>
                        {timeline.map((item, index) => <li key={index} className='flex flex-col items-center gap-[4px]'>
                            <p className='text-[10px] leading-[13px] tracking-[0.04em] text-center text-[#0B0B0B80]'>
                                {new Date(item.date).toLocaleDateString()} <br/>
                                {item.day}
                                {/* <br /> { item.percentage } */}
                            </p>
                            <div className='h-[4px] w-[1px] bg-[#0B0B0B40]'/>
                        </li>)}
                    </ul>
                    <div className='px-[12px] py-[4px]'>
                        <Slider
                            start={sliderPercentage}
                            onChange={(e)=>{
                                console.log(e)
                                setSliderPercentage(+e);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
};

export default TimelineSimulationNew;