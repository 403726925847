import React, { useRef, useState, useEffect } from "react"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputBox from "./base/InputBox";
import PanelButton from "./base/PanelButton";
import { ReactComponent as NewWaypointIcon } from "../assets/img/icons/new-waypoint.svg";
import { ReactComponent as AnchorIcon } from "../assets/img/icons/anchor-solid.svg";
import { ReactComponent as UploadIcon } from "../assets/img/icons/upload.svg";
import { ReactComponent as SaveIcon } from "../assets/img/icons/save.svg";
import { ReactComponent as PenIcon } from "../assets/img/icons/pen.svg";
import { ReactComponent as DownloadIcon } from "../assets/img/icons/load.svg";
import { ReactComponent as CheckIcon } from "../assets/img/icons/check.svg";
import { ReactComponent as CopyIcon } from "../assets/img/icons/copy.svg";
import FilterMenuDropdown from "./base/FilterMenuDropdown";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import api from '../api';
import { getAllMissions, getCurrentMission, setMissonStatus } from "../store/slices/missionSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllMission, fetchMissionById } from "../store/thunks/fleetThunks";
import { useClickOutside } from '../helpers/useClickOutside';
import { toast } from "react-toastify";
import {getUser} from "../store/slices/applicationSlice";

export function convertToCSV(data) {
    const headers = Object.keys(data[0]); // Получаем заголовки столбцов
    const csvRows = [];

    // Добавляем строку заголовков
    csvRows.push(headers.join(','));

    // Добавляем строки данных
    for (const row of data) {
        const values = headers.map(header => row[header]);
        csvRows.push(values.join(','));
    }

    return csvRows.join('\n'); // Объединяем строки в CSV
}

export function downloadCSV(csvString, filename) {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}

function transformObjectKeys(obj) {
    const newObj = {};
    for (const key in obj) {
        const newKey = "mission" + key.charAt(0).toUpperCase() + key.slice(1);
        newObj[newKey] = obj[key];
    }
    return newObj;
}

function parseCSVFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const csvString = event.target.result;
            const parsedData = parseCSV(csvString); // Используем функцию parseCSV из предыдущего ответа
            resolve(parsedData);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsText(file);
    });
}
function parseCSV(csvString) {
    const lines = csvString.split('\n');
    const headers = lines[0].split(',');
    const data = [];

    for (let i = 1; i < lines.length; i++) {
        const values = lines[i].split(',');
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = values[j];
        }
        data.push(obj);
    }

    return data;
}

const MissionPlanerControls = ({ mapOptions, handleSetAddWayPointsMode, handleAddStationPointsMode, setOpenedModal, addWayPointsMode, setAddWayPointsMode, addStationPointsMode, setAddStationPointsMode, addFromCoordinatesArray, missionId, historicaMissionIdsForShow, setHistoricaMissionIdsForShow, wayPoints, currentMissionStatus, hasLoop }) => {
    const dispatch = useDispatch();
 
    const currentMission = useSelector(getCurrentMission)
    const user = useSelector(getUser)
    const [selectedDate, setSelectedDate] = useState(null);

    const [inputCoordinatesValue, setInputCoordinatesValue] = useState("");

    const [fleets, setFleets] = useState([]);
    const [allVessels, setAllVessel] = useState([]);

    const handleChange = (date) => {
        setSelectedDate(date);
    };


    const isDisabledOwner = currentMission && user ? currentMission?.userId !== user?.id : true

    const [open, setOpen] = useState([])

    const [vesselsInputValue, setVesselsInputValue] = useState("");
    const [vesselsDropdownOpen, setVesselsDropdownOpen] = useState(false);

    const dropdownRef = useRef();
    useClickOutside(dropdownRef, () => setVesselsDropdownOpen(false));

    const openList = (idx) => {
        open.includes(idx) ? setOpen(prev => [...prev].filter(el => el !== idx)) : setOpen(prev => [...prev, idx])
    }

    const addCoordinatesFromInput = () => {
        if (!inputCoordinatesValue?.length || !inputCoordinatesValue.includes(',')) {
            return false;
        }

        if (inputCoordinatesValue) {
            addFromCoordinatesArray([{
                "lat": inputCoordinatesValue.split(",")[0],
                "lon": inputCoordinatesValue.split(",")[1],
                "title": "Test",
                "missionId": missionId
            }]);

            setInputCoordinatesValue("");
        }
    }

    const csvFileSelected = (e) => {
        if (!e.target.files.length) {
            return;
        }
        const file = e.target.files[0];
        parseCSVFile(file)
            .then(data => {
                if (data?.length) {
                    addFromCoordinatesArray(data.map((item, index) => ({
                        "lat": item.lat,
                        "lon": item.lon,
                        "title": "Test",
                        "missionId": missionId,
                        repeat_trajectory: item?.repeat_trajectory || null,
                        targetRadius: parseInt(item?.targetRadius) || 1,
                        isReached: false,
                        index
                    })));
                }
            })
            .catch(error => {
                console.error('Ошибка при чтении CSV:', error);
            });


    }

    const getAllFleets = async () => {
        let res = await api.fleet.getAllFleets();
        let res2 = await api.fleet.getAllVessel();

        setAllVessel(res2.data);

        setFleets(res.data.map(item => ({
            ...item,
            vessels: res2?.data?.filter(itemV => +itemV?.fleetId === +item?.id) || []
        })));
    }

    const selectVessel = async (vesselId) => {
        let res = await api.fleet.updateMission({
            vesselId
        }, missionId);


        if (res.success) {
            const a = allVessels.find(el => el.id === vesselId)

            if (a) {
                setVesselsInputValue(a.name)
            }
        }



        dispatch(fetchAllMission());
    }

    useEffect(() => {
        getAllFleets();
        dispatch(fetchAllMission());
        dispatch(fetchMissionById(missionId))
    }, []);

    useEffect(() => {
        if (currentMission && allVessels) {
            setVesselsInputValue(allVessels.find(item => item.id === currentMission.vesselId)?.name || "");
        }
    }, [currentMission, allVessels]);


    const handleSetHistoricaMissionIdsForShow = (missionId) => {
        setHistoricaMissionIdsForShow(prev => {
            const newSet = new Set(prev);
            if (newSet.has(missionId)) {
                newSet.delete(missionId);
            } else {
                newSet.add(missionId);
            }
            return Array.from(newSet);
        });
    };
    console.log(wayPoints)

    return (
        <>
            <div
                className="w-[186px] pointer-events-auto rounded-[4px] p-[8px] border border-[var(--oshen-primary-25)] bg-[#FBFBFB] dark:bg-[#1B1B1B] flex flex-col gap-[8px]"
                style={{ maxHeight: 'calc(100vh - 192px)', overflowY: 'auto' }}
            >
                <div className="border border-[#0B0B0B1A] bg-[#EFF2F4] rounded-[4px] p-[8px] relative" ref={dropdownRef}>
                    <InputBox
                        disabled={isDisabledOwner}
                        iconType='sail' placeholder='Select Vessel'
                        value={vesselsInputValue}
                        onInput={(e) => { setVesselsInputValue(e.target.value); }}
                        onFocus={() => { setVesselsDropdownOpen(true); }}
                    // onBlur={()=>{ setVesselsDropdownOpen(false); }}
                    />
                    {vesselsDropdownOpen ?
                        <ul className="absolute right-[-1px] top-[calc(100%+2px)] rounded-[2px] border border-[#0B0B0B1A] bg-[#EFF2F4] min-w-[calc(100%+2px)] z-10 max-h-[180px] overflow-y-auto">
                            {
                                allVessels?.filter(itemF => itemF.name.toLowerCase().includes(vesselsInputValue.toLowerCase()))?.map((item, index) => {
                                    return <li key={index}>
                                        <a
                                            href="#" className="block py-[5px] px-[10px] hover:text-[#E42320]"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                selectVessel(item.id);
                                                setVesselsDropdownOpen(false);
                                            }}
                                        >{item.name}</a>
                                    </li>
                                })
                            }
                        </ul> : null}
                </div>
                {currentMission?.status !== 'complete' && <div className="border border-[#0B0B0B1A] bg-[#EFF2F4] rounded-[4px] p-[8px] flex flex-col gap-[8px]">
                    <InputBox
                        disabled={isDisabledOwner}
                        placeholder='Input Coordinates'
                        value={inputCoordinatesValue}
                        onInput={(e) => {
                            setInputCoordinatesValue(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                addCoordinatesFromInput();
                            }
                        }}
                    />

                    {!addWayPointsMode && <PanelButton disabled={isDisabledOwner} onClick={() => handleSetAddWayPointsMode(true)}>
                        <NewWaypointIcon className="icon-white" />
                        Edit Waypoints
                    </PanelButton>}
                    {addWayPointsMode && <PanelButton onClick={() => handleSetAddWayPointsMode(false)}>
                        <NewWaypointIcon className="icon-white" />
                        Cancel Adding
                    </PanelButton>}
                    <label className={`group flex items-center justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917] cursor-pointer ${isDisabledOwner ? 'disabled-btn-panel' : ''}`}>
                        <UploadIcon className="icon-white" />
                        <span style={{ whiteSpace: 'nowrap' }}>Upload Coordinates</span>
                        <input disabled={isDisabledOwner} type="file" className="hidden" onChange={csvFileSelected} />
                    </label>
                    {/*{!addStationPointsMode && <PanelButton disabled={isDisabledOwner} onClick={() => handleAddStationPointsMode(true)}>*/}
                    {/*    <AnchorIcon className="icon-white size-[24px]" />*/}
                    {/*    Add Station Points*/}
                    {/*</PanelButton>}*/}
                    {/*{addStationPointsMode && <PanelButton onClick={() => handleAddStationPointsMode(false)}>*/}
                    {/*    <AnchorIcon className="icon-white size-[24px]" />*/}
                    {/*    Cancel Adding*/}
                    {/*</PanelButton>}*/}
                </div>}
                {/*<div className="flex flex-col text-xs rounded border border-solid border-[#0b0b0b1a] dark:border-[#ffffff40] bg-[#eff2f4] dark:bg-[#1B1B1B]">*/}
                {/*    <div className="p-[8px] cursor-pointer flex justify-between items-center" onClick={openList}>*/}
                {/*        <div className="font-semibold text-neutral-950 dark:text-white">Show Other Vessels</div>*/}
                {/*    </div>*/}
                {/*    <div className={`w-full`}>*/}
                {/*        {fleets.map((item, index) => (*/}
                {/*            <FilterMenuDropdown*/}
                {/*                fleet={item}*/}
                {/*                index={index}*/}
                {/*                key={index}*/}
                {/*                open={open}*/}
                {/*                openList={openList}*/}
                {/*            />*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="flex flex-col text-xs p-[8px] bg-gray-100 rounded border border-solid border-[#0b0b0b1a] dark:border-[#ffffff40] bg-[#eff2f4] dark:bg-[#1B1B1B]">
                    <div className="font-semibold text-neutral-950 mb-[4px] dark:text-white">Map Option</div>

                    <div className="w-full">
                        {mapOptions.map((el, index) => (
                            <label key={index} htmlFor={`map-option-${index}`} className="cursor-pointer flex h-[24px] mb-[4px] last:mb-[0px] justify-between items-center">
                                <div className="flex justify-between pl-[4px] items-center">
                                    <input defaultChecked={el.default} type="radio" className="w-[16px] h-[16px] radio-red" id={`map-option-${index}`} name="map-option" onChange={el.onSelect} />
                                    <span className="font-semibold pl-[8px] text-neutral-950 text-opacity-50 dark:text-[#ffffff80]">{el.label}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>

                {/*<div className="flex flex-col text-xs p-[8px] bg-gray-100 rounded border border-solid border-[#0b0b0b1a] dark:border-[#ffffff40] bg-[#eff2f4] dark:bg-[#1B1B1B]">*/}
                {/*    <div className="font-semibold text-neutral-950 mb-[4px] dark:text-white">Historical Tracks</div>*/}

                {/*    <div className="w-full">*/}

                {/*        {*/}
                {/*            historicalMissions ? historicalMissions.length && historicalMissions.map((mission, index) => (*/}
                {/*                <label key={index} htmlFor={`vessel-option-${index}`} className="cursor-pointer flex h-[24px] mb-[4px] last:mb-[0px] justify-between items-center">*/}
                {/*                    <div className="flex justify-between pl-[4px]">*/}
                {/*                        <div className="checkbox-red-cont">*/}
                {/*                            <input type="checkbox" checked={historicaMissionIdsForShow.includes(mission.id)} onChange={() => handleSetHistoricaMissionIdsForShow(mission.id)} className="w-[16px] h-[16px]" id={`vessel-option-${index}`} />*/}
                {/*                            <span className="checkmark-red"></span>*/}
                {/*                        </div>*/}

                {/*                        <span className="font-semibold pl-[8px] text-neutral-950 text-opacity-50 dark:text-[#ffffff80]">{mission?.description}</span>*/}
                {/*                    </div>*/}
                {/*                </label>*/}
                {/*            ))*/}
                {/*                :*/}
                {/*                null*/}
                {/*        }*/}

                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="flex flex-col text-xs p-[8px] bg-gray-100 rounded border border-solid border-[#0b0b0b1a] dark:border-[#ffffff40] bg-[#eff2f4] dark:bg-[#1B1B1B]">*/}
                {/*    <div className="font-semibold text-neutral-950 mb-[4px] dark:text-white">Other</div>*/}

                {/*    <div className="w-full">*/}
                {/*        <label htmlFor={`label-show-metrics`} className="cursor-pointer flex h-[24px] mb-[4px] last:mb-[0px] justify-between items-center">*/}
                {/*            <div className="flex justify-between pl-[4px]">*/}
                {/*                <div className="checkbox-red-cont">*/}
                {/*                    <input type="checkbox" className="w-[16px] h-[16px]" id={`label-show-metrics`}*/}
                {/*                        onChange={(e) => {*/}

                {/*                            const el = document.getElementById('legend-mobile');*/}
                {/*                            if (window.customWindy.store.get('overlay') === 'temp') {*/}
                {/*                                el.style.display = 'none'*/}
                {/*                                e.target.checked = false*/}
                {/*                                return*/}
                {/*                            }*/}
                {/*                            el.style.display = e.target.checked ? 'block' : 'none'*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                    <span className="checkmark-red"></span>*/}
                {/*                </div>*/}

                {/*                <span className="font-semibold pl-[8px] text-neutral-950 text-opacity-50 dark:text-[#ffffff80]">Show Metrics</span>*/}
                {/*            </div>*/}
                {/*        </label>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <PanelButton style="alt" Icon={DownloadIcon} className='w-full' onClick={() => {
                    const transformedObject = transformObjectKeys(currentMission);
                    const csvString = convertToCSV(wayPoints.map(({lat, lon}) => ({
                        lat, lon
                    })));

                    downloadCSV(csvString, `mission-${currentMission.id}.csv`);
                }}>
                    Download CSV
                </PanelButton>
                {/*<PanelButton style="alt" Icon={CopyIcon} className='w-full' onClick={async() => {*/}
                {/*    const res = await api.fleet.cloneMission(missionId);*/}
                {/*    console.log(res)*/}
                {/*    toast(res.success ? 'Mission Duplicated Successfully!' : 'Error', {*/}
                {/*        type: res.success ? 'success': 'error'*/}
                {/*    })*/}
                {/*    // if(res.success) {*/}
                {/*    //     dispatch(setMissonStatus("complete"))*/}
                {/*    // }*/}
                {/*    */}
                {/*}}>*/}
                {/*    Duplicate Mission*/}
                {/*</PanelButton>*/}
                <Link to='/mission-planner'>
                    <PanelButton style="alt" Icon={PenIcon} className='w-full'>
                        Manage Missions
                    </PanelButton>
                </Link>

                {currentMission && currentMission.status === 'draft' && <PanelButton disabled={isDisabledOwner} style="alt" Icon={SaveIcon} onClick={() => setOpenedModal('save')}>
                    Save Draft
                </PanelButton>}
                {currentMission && currentMission.status !== 'complete' && <PanelButton disabled={isDisabledOwner} Icon={CheckIcon} onClick={async () => {
                    const pushRes = await api.fleet.pushMission(missionId);
                    console.log(pushRes)
                    toast(pushRes.message, {
                        type: pushRes.success ? 'success' : 'error'
                    })
                    if (!pushRes.success) {
                        return
                    }
                    const res = await api.fleet.updateMission({
                        status: "in_progress",
                    }, missionId);
                    console.log(res)
                    if (res.success) {
                        dispatch(setMissonStatus("in_progress"))
                    }
                }}>
                    Push Mission
                </PanelButton>}
                {currentMission?.status === 'in_progress' && <PanelButton disabled={isDisabledOwner} onClick={async() => {
                    const res = await api.fleet.updateMission({
                        status: "complete",
                    }, missionId);
                    console.log(res)
                    toast(res.success ? 'Mission Finished!' : 'Error', {
                        type: res.success ? 'success': 'error'
                    })
                    if(res.success) {
                        dispatch(setMissonStatus("complete"))
                        const filters = JSON.parse(localStorage.getItem("filters")) || []
                    
                        const newFilter = {
                            vessel: res.data.vesselId,
                            id: filters.length + 1,
                            name:  res.data.description,
                            startDate: res.data.start,
                            endDate: res.data.end
                        }
                        const newFilters = [...filters, newFilter]
                        console.log(newFilters)
                        localStorage.setItem("filters", JSON.stringify(newFilters))
                        }
                    
                }}>
                    Finish Mission
                </PanelButton>}
            </div>
        </>
    )
}

export default MissionPlanerControls;