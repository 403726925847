import { createSlice } from '@reduxjs/toolkit'
import { fetchUserThunk, logoutThunk } from '../thunks/applicationThunks';
import { fetchCreateFilter, fetchDeleteFilter, fetchFilters, fetchUpdateFilter } from '../thunks/filtersThunks';

const initialState = {
    filters: []
}

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setAuthorized: (state, action) => {
            state.authorized = action.payload;
        },

        setUser: (state, action) => {
            state.user = action.payload;
        },

        setToken: (state, action) => {
            state.token = action.payload;
        },
    },

    extraReducers(builder) {
        builder.addCase(fetchFilters.fulfilled, (state, action) => {
            console.log(action.payload)
            state.filters = action.payload
        });
        builder.addCase(fetchCreateFilter.fulfilled, (state, action) => {
            console.log(action.payload)
            state.filters.push(action.payload)
        });
        builder.addCase(fetchUpdateFilter.fulfilled, (state, action) => {
            console.log(action.payload)
            state.filters = state.filters.map(filter => filter.id === action.payload.id ? action.payload : filter)
        });
        builder.addCase(fetchDeleteFilter.fulfilled, (state, action) => {
            console.log(action.payload)
            state.filters = state.filters.filter(filter => filter.id !== action.payload)
        });
    }
})

export const { 
    setUser, setAuthorized, setToken,
} = filtersSlice.actions;

export const getUser = (state) => state.application.user;
export const getAuthorized = (state) => state.application.authorized;
export const getToken = (state) => state.application.token;
export const getFilters = (state) => state.filters.filters;

export default filtersSlice.reducer