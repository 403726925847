import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getAuthorized,  } from '../store/slices/applicationSlice';


const ProtectedRouteLayout = () => {
    const authorized = useSelector(getAuthorized);

    if(authorized !== true) {
        return null
    }
    

    return (
        <Outlet />
    )
};

export default ProtectedRouteLayout