import {Line} from "react-chartjs-2";
import React, {useRef} from "react";
import zoomPlugin from 'chartjs-plugin-zoom';

import {
    Chart as ChartJS,
    // CategoryScale,
    // LinearScale,
    // PointElement,
    // LineElement,
    // Title,
    // Tooltip,
    // Legend
} from 'chart.js';

ChartJS.register(
    // CategoryScale,
    // LinearScale,
    // PointElement,
    // LineElement,
    // Title,
    // Tooltip,
    // Legend,
    zoomPlugin
);

export const LineChartCustom = ({data}) => {
    const chartRef = useRef(null)

    const dataSourceColors = ['#caddf5', '#d0e9f8'];
    const chartData = {
        ...data.data,
        backgroundColor: dataSourceColors
    }

    const options= {
        ...data.options,
        responsive: true,
        maintainAspectRatio: false,
    }

    options.plugins.legend = {
        display: true,
            position: "right",
            align: "start",
            labels: {
            boxWidth: 12,
                boxHeight: 12,
                fillStyle: '#caddf5',
        },
    }

    options.plugins.zoom = {
        zoom: {
            wheel: {
                enabled: true,
            },
            pinch: {
                enabled: true,
            },
            mode: 'x',
        },
        pan: {
            enabled: true,
            mode: 'x',
        },
    }

    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom()
        }
    }


    return (
        <div className="flex w-full mb-[24px] relative">
            <Line ref={chartRef} data={chartData} options={options}/>
            <button onClick={handleResetZoom} style={{position: 'absolute', right: 0, bottom: 0}} className=" flex items-center justify-center gap-1 font-semibold h-8 px-3 text-xs tracking-[4%] rounded border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917] w-max">
                Reset zoom
            </button>
        </div>
    )
}