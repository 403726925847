import classNames from 'classnames';
import React from 'react';

const PanelButton = ({ 
    style = 'default', Icon, onClick, disabled, className, children, ...props
}) => {
    return <button
        className={classNames(
            'group flex items-center justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border',
            {
                'disabled-btn-panel': disabled,
                'text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]': style === 'default',
                'text-[#E42320] border-[#E42320] bg-transparent hover:bg-[#E4232040] active:bg-transparent active:text-[#0B0B0B80] active:border-[#0B0B0B80]': style === 'alt',
            },
            className
        )}
        onClick={onClick}
        disabled={disabled}
        {...props}
    >
        {Icon && <Icon className={classNames(
            'min-w-[24px]', 
            {

                'icon-white': style === 'default',
                'icon-oshen-primary group-active:icon-black-50': style === 'alt',
            }
        )}/>}
        {children}
    </button>
};

export default PanelButton;