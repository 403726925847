import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes.js";
import { useState, createContext, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";

// import css
import './assets/css/custom.css'
import './assets/css/dash.css'
import { fetchUserThunk } from "./store/thunks/applicationThunks.js";
import AuthModal from "./Modals/AuthModal.jsx";
import { getAuthorized } from "./store/slices/applicationSlice.js";
import {io} from "socket.io-client";
import {getSocket, setSocket} from "./store/slices/shipsSocket";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

export const ThemeContext = createContext();

function App() {
    const [theme, setTheme] = useState('')

    const changeTheme = (e) => {
        theme ? setTheme('') : setTheme('dark')
    }
    const dispatch = useDispatch();
    const authorized = useSelector(getAuthorized);
    const socket = useSelector(getSocket);
    useEffect(() => {

        const req = async () => {
            const res = await dispatch(fetchUserThunk());
            if (res?.payload?.isDarkMode) {
                res.payload.isDarkMode ? setTheme('dark') : setTheme('')
            }
        }
        req()

    }, [dispatch])

    useEffect(() => {
        if(authorized === null || !socket) return
        if(authorized === true) {
            const token = localStorage.getItem('authToken');
            if(!token) return
            socket.auth = {
                token
            }
            socket.connect()
        }
        if(authorized === false) {
            socket.disconnect()
        }
        

    }, [authorized, socket])

    useEffect(() => {
        const socket = io('https://oshen.webstaginghub.com', {
            path: '/socket',
            transports: ['websocket'],
            reconnection: true,
            reconnectionDelayMax: 10000,
            reconnectionDelay: 10000,
            autoConnect: false
        });
        dispatch(setSocket(socket))
    }, [dispatch])
    return (
        <ThemeContext.Provider value={{ theme, changeTheme }}>

            <div className={`App ${theme} min-h-screen`}>
                {/* <button onClick={changeTheme} className="theme-button ">{theme ? 'White mode' : 'Dark mode'}</button> */}
                <RouterProvider router={router} />
                {authorized === false && <AuthModal />}
            </div>
            <ToastContainer />

        </ThemeContext.Provider>
    );
}

export default App;
